import React from 'react';
import './Home.css';
// import Carousel from '../carousel/Carousel';
import Insta from '../../images/Instagram.jpeg';
import Face from '../../images/facebook.jpeg';
import Whats from '../../images/whatsapp.jpeg';
import SamOne from '../../images/samOne.jpg';
import SamTwo from '../../images/samTen.jpg';
import SamThree from '../../images/samNine.jpg';
import CardImgae from '../carousel/CardImgae';

const Home = () => {

    const slides = [SamOne,SamTwo,SamThree];

  return (
    <section className="px-5 mt-24 pt-10 h-[80vh]" id="home">
        <div className="home-container1">
            <div className="flex flex-col">
                <div className="home-heading ml-[10px]">
                    <h3>mujib</h3>
                    <h4>law associate</h4>
                </div>
                <div className="flex justify-center mt-[-30px]">
                <CardImgae />
                </div> 
                <div>
                    <p className="text-center mt-10">Our law firm, Mujib Law Associate,has a longstanding reputation for being a distinguished law firm dedicated to serving a variety of needs.</p>
                </div>
                <div className="flex justify-center items-center flex-col contact-btn">
                    <button className="p-2 text-center mb-6"><a href='#about'>Know More</a></button>
                    <button className="p-2 text-center"><a href='tel: +919894208186'>Consultation</a></button>
                </div>
            </div>
        </div>
        <div className="home-container2 w-full mt-10">
            <div className="w-1/2 heading-containerBox1">
                <div className="home-container2-heading w-[400px] mb-5 contentOne">
                    <h2>Mujib</h2>
                    <h3>Law Associate</h3>
                </div>
                <div className="mb-7 contentTwo"> 
                    <p>Our law firm,Mujib Law Associates,
                        has a longstanding reputation for being
                        a distinguished law firm dedicated to
                        serving a variety of needs. With a team 
                        of lawyers who are highly skilled and
                        experienced, our lawyers are
                        commited to providing clients with 
                        legal representation that is 
                        unsurpassed.
                    </p>
                </div>
                <div className="flex contact-btn gap-3 mb-7 contentThree">
                    <button className="p-2 text-center"><a href='#about'>Know More</a></button>
                    <button className="p-2 text-center"><a href='tel: +919894208186'>Consultation</a></button>
                </div>
                <div className="flex ml-20 contentFour">
                    <a href="https://www.facebook.com/share/gk92WZzAxSJiidNU/?mibextid=LQQJ4d"><img src={Face} className="w-[70px]"/></a>
                    <a href="https://www.instagram.com/mujiburahman786?igsh=NGdqNmR2YzZjcGxu&utm_source=qr"><img src={Whats} className="w-[70px]"/></a>
                    <a href="te: +91 9894208186"><img src={Insta} className="w-[70px]"/></a>
                </div>
            </div>
            <div className="flex justify-center w-1/2">
                <CardImgae />
            </div> 
        </div>
    </section>
  )
}

export default Home;