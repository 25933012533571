import React from 'react';
import '../blog-routes/BlogRoutes.css';
import { useNavigate } from 'react-router-dom';

const Criminal = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-28 mb-6 px-10">
        <div>
            <h2>What is Family Law?</h2>
            <p>Family Law is a branch of law that deals with legal matters related to family and personal relationships.
                 It encompasses a wide range of issues, including marriage, divorce, child custody, adoption, guardianship,
                  and reproductive rights. Family advocate specialize in handling cases related to family law and 
                provide legal advice and assistance to individuals involved in legal matters involving their family.</p>
        </div>
        <div>
            <h2>Divorce</h2>
            <p>Divorce is a legal process that dissolves a marriage. It can involve complex legal procedures and emotions,
                 making it necessary to involve a family law advocate. A divorce lawyer in Tambaram
                  can assist individuals throughout the divorce process, including filing for divorce, negotiating 
                  settlements, and representing their interests in court if necessary.There are various grounds for divorce,
                   such as irreconcilable differences, adultery, abuse, and abandonment. Each ground has its own set of requirements and procedures that need to be followed. A family law attorney can help individuals understand these grounds and determine which one applies to their case.</p>
        </div>
        <div>
            <h2>Maintenance</h2>
            <p>Maintenance, also known as spousal support or alimony, is a financial obligation that one spouse
                 may have to pay to the other after a divorce. It is designed to provide financial assistance to the 
                 dependent spouse and help them maintain their standard of living.
                To obtain maintenance, the dependent spouse needs to file a maintenance application with the court.
                 The court will take into consideration various factors, such as the length of the marriage, the earning
                  capacity of each spouse, and the financial needs and circumstances of the dependent spouse. A family 
                  law advocate can help individuals navigate the maintenance application process and ensure that their rights are protected.</p>
        </div>
        <div>
            <h2>Muslim Divorce</h2>
            <p>Muslim divorce has its own unique set of requirements and procedures that differ from other divorces.
                 Under Muslim family law, the dissolution of marriage is done through an Islamic divorce, also known
                  as a talaq. To obtain a Muslim divorce, certain formalities need to be fulfilled, such as reciting 
                  the divorce formula in front of witnesses. Additionally, there are certain requirements regarding 
                  the distribution of the marital assets and custody of any children. A family law advocate with
                   experience in the specifics of Muslim divorce can help individuals navigate the complex legalities 
                   and ensure that their rights and interests are protected.</p>
        </div>
        <div>
            <h2>Conjugal Rights</h2>
            <p>Conjugal rights are the legal entitlements that a spouse has to the other spouse's physical intimacy, 
                companionship, and support. These rights are recognized and protected under the law, and they can be enforced through legal means.</p>
            <p>If a spouse is denying conjugal rights without valid reason, the non-offending spouse can approach 
                the court to seek relief. The court can order the denying spouse to fulfill conjugal rights and provide a
                 remedy to the non-offending spouse. A family law advocate can assist individuals in understanding 
                the legal implications of conjugal rights and guide them through the legal process to enforce them.</p>
        </div>
        <div>
            <h2>Mutual Consent</h2>
            <p>A mutual consent divorce is a divorce where both spouses agree to end their marriage. It
                 is a less adversarial process and can be pursued without the need for litigation. To obtain
                  a mutual consent divorce, both spouses need to file a joint petition for divorce and 
                fulfil certain requirements, such as attending counselling sessions or mediation.</p>
            <p>A family law attorney can help individuals navigate the legal requirements of a mutual consent
                 divorce and ensure that all necessary documents are correctly filed. They can also help negotiate
                  the terms of a settlement and advise clients on their best options in reaching a mutually agreeable agreement.</p>
        </div>
        <div className="prev-btn-blog"><button onClick={()=>navigate('/')}>Go to Previous</button></div>
    </div>
  )
}

export default Criminal