import React, {useState} from 'react';
import SamOne from '../../images/samOne.jpg';
import SamTwo from '../../images/samTen.jpg';
import SamThree from '../../images/samNine.jpg';
import {FaGreaterThan,FaLessThan} from 'react-icons/fa';
import {RxDotFilled} from 'react-icons/rx';
import './CardImage.css';

const CardImgae = () => {

    const slide = [SamOne,SamTwo,SamThree];

    const [currentIndex,setCurrentIndex] = useState(0);

    const prevSlide =()=>{
        const isFirstSlide = currentIndex ===0;
        const newIndex = isFirstSlide ? slide.length -1 : currentIndex -1;
        setCurrentIndex(newIndex);
    };

    const nextSlide =()=>{
        const isLastSlide = currentIndex === slide.length -1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1 ;
        setCurrentIndex(newIndex); 
    };

    const goToSlide = (slideIndex)=>{
        setCurrentIndex(slideIndex);
    };

  return (
    <div className="mt-10 max-w-[500px] h-[400px] w-[500px] relative group card-image10">
        <div className="w-full h-full rounded-2xl bg-center bg-cover duration-500" style={{backgroundImage:`url(${slide[currentIndex]})`}}></div>
        <div className="hidden absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <FaLessThan size={30} onClick={prevSlide}/>
        </div>
        <div className="hidden absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <FaGreaterThan size={30} onClick={nextSlide}/>
        </div>
        <div className="flex top-4 justify-center py-2">
            {slide.map((slide,slideIndex)=>(
                <div className="text-2xl cursor-pointer" key={slideIndex} onClick={()=>goToSlide(slideIndex)}>
                    <RxDotFilled />
                </div>
            ))}
        </div>
    </div>
  )
}

export default CardImgae;