import React from 'react';
import './App.css';
import Full from './components/full/Full';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Civil from './components/service-routes/Civil';
import Header from './components/header/Header';
import MoneyFraud from './components/blog-routes/MoneyFraud';
import Tribunal from './components/blog-routes/Tribunal';
import TripleTalaq from './components/blog-routes/TripleTalaq';
import Criminal from './components/service-routes/Criminal';
import Family from './components/service-routes/Family';
import Muslim from './components/service-routes/Muslim';

const App = () => {
  return (
    <>
    <BrowserRouter>
    <Header />
      <Routes>
        <Route path='/' element={<Full/>}/>
        <Route path='/civil' element={<Civil/>}/>
        <Route path='/money' element={<MoneyFraud />}/>
        <Route path='/tribunal' element={<Tribunal />} />
        <Route path='/triple' element={<TripleTalaq />} />
        <Route path='/family' element={<Family />} />
        <Route path='/criminal' element={<Criminal />} />
        <Route path='/muslim' element={<Muslim/>} />
      </Routes>
    </BrowserRouter>
    </>
  )
}

export default App;