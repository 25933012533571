import React from 'react'
import './BlogRoutes.css';
import {useNavigate} from 'react-router-dom';

const TripleTalaq = () => {
    const navigate = useNavigate();
  return (
    <div className="mt-28 px-10 mb-6">
        <h1>A critical study of the Supreme Court's decision on the Triple Talaq.</h1>
        <h2>Introduction:</h2>
        <div>
            <p>
            The Triple Talaq, also known as instantaneous divorce, is a controversial practice prevalent
             in some Muslim communities. It allows a man to unilaterally divorce his wife by pronouncing the
              word "talaq" three times. This practice has generated significant debate and concerns, particularly
               among women's rights activists and advocates for gender equality. In this blog post, we will explore the
             Triple Talaq through a case study and discuss how it impacts the lives of Muslim women.
            </p>
            <p>
            The Supreme Court struck down the practice of triple Talaq and declared it unconstitutional in
             the Shayara Banu case. This was a landmark case under family laws that dealt with the practice of 
             triple Talaq (instant divorce) among Muslims. 
            Shayara Bano, a Muslim woman, challenged the constitutional validity of triple Talaq, nikah halala, and polygamy. 
            </p>
            <p>
            She claimed they violated her fundamental rights. Shayara Bano argued that the practice of triple Talaq
             violated her basic rights as it allowed her husband to divorce her without her consent or any legal recourse 
             unilaterally. She contended that this practice was discriminatory and treated women as subordinate to men,
              undermining their dignity and autonomy. Additionally, she highlighted the negative consequences
             of triple Talaq on women's financial security, emotional well-being, and the stability of their families.
            </p>
        </div>
        <h2>Case Study</h2>
        <div>
            <p>To illustrate the detrimental effects of the Triple Talaq on individual lives, let us consider 
                the story of Aisha, a Muslim woman who found herself on the receiving end of this discriminatory practice.
                 Aisha has been happily married for over ten years and has two young children.
                 However, her husband, Ahmed, suddenly decided to end their marriage by uttering the three-word divorce.</p>
            <p>
            Aisha was shocked and devastated. She had devoted her life to her family and never imagined that 
            her husband would discard her so easily. The triple Talaq shattered her dreams, leaving her 
            feeling abandoned and betrayed. 
            Aisha's two young children, who were dependent on her, now faced an uncertain and uncertain future.
            </p>
        </div>
        <h2>Impact on Women's Rights</h2>
        <div>
            <p>
            The Triple Talaq undermines women's rights and gender equality within Muslim communities.
             It perpetuates the notion that women are mere possessions that can be abandoned at the whim 
             of their husbands. The practice disregards 
            the dignity and autonomy of Muslim women, denying them the right to fair treatment and equal standing in society.
            </p>
        </div>
        <h2>The Need for Reform</h2>
        <div>
            <p>
            Recognizing the urgent need for reform, various Muslim organizations and advocates have
             been actively campaigning against the Triple Talaq. They argue that this practice
             goes against the principles of justice, compassion, and equality enshrined in Islamic teachings.
            </p>
            <p>
            These advocates are calling for the abolition of the Triple Talaq and implementing a more equitable
             divorce system. They propose alternatives such as 
            mediation or arbitration, allowing couples to resolve disputes without resorting to unilateral divorce.
            </p>
        </div>
        <h2>Conclusion</h2>
        <div>
            <p>
            As a result of Aisha's case study, it is clear that Triple Talaq can have devastating
             impacts on Muslim women's lives. A comprehensive reform of this discriminatory practice is required.
              To create a more inclusive and equitable society for all, Muslim organizations
             and individuals must work together to promote gender equality, respect, and justice within Muslim communities.
            </p>
            <p>
            As a result of the Supreme Court's decision in the Shayara Banu case, Muslims 
            have been fighting Triple Talaq since the practice violates their fundamental rights. 
            As a result of this landmark ruling, the reform
             movement has gained momentum, and countless women like Aisha who have suffered this discrimination now have hope.
            </p>
        </div>
        <div className="prev-btn-blog"><button onClick={()=>navigate('/')}>Go to Previous</button></div>
    </div>
  )
}

export default TripleTalaq