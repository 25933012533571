import React from 'react';
import "./Service.css";
import CardSlider from './CardSlider';
import CardSliders from './CardSliders';

const Service = () => {

  return (
    <>
      <CardSlider />
      <CardSliders />
    </>
  )
}

export default Service;