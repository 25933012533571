import React from 'react';
// import Header from '../header/Header';
import Home from '../home/Home';
import About from '../about/About';
import Service from '../service/Service';
import Blog from '../blog/Blog';
import Career from '../career/Career';
import Contact from '../contact/Contact';

const Full = () => {
  return (
    <>
      <Home />
      <About />
      <Service />
      <Blog />
      <Career />
      <Contact />
    </>
  )
}

export default Full