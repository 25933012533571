import React from 'react';
import {FaAngleDown} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';

const CardSlider = () => {

  const navigate = useNavigate();

    const data = [{
        title:"Criminal Law :",
        description:`Criminal law is a complex system that governs criminal offenses and penalties.
        It encompasses a wide range of offenses, including violent crimes, property crimes,
         white-collar crimes, and other crimes that threaten the safety and welfare of society.
          The purpose of criminal law is to protect`,
          walk:`/family`
    },{
        title:"Family Law :",
        description:`Family Law is a branch of law that deals with legal matters related to family and personal relationships.
        It encompasses a wide range of issues, including marriage, divorce, child custody, adoption, guardianship,
         and reproductive rights. Family advocate specialize in handling cases related to family law and 
       provide legal `,
       walk:`/criminal`
    },{
        title:"Muslim law :",
        description:`Muslim Law, also known as Shariah Law, is a legal system that is 
        based on the teachings and principles of Islam. It governs various aspects of life, such as personal status, family relations, and financial transactions. Muslim advocate in 
        Tambaram are experts in this area of law and can provide valuable.`,
        walk:`/muslim`
    },{
        title:"Civil Law :",
        description:`Civil law is a legal system that governs disputes between individuals or organizations.
        It encompasses a wide range of legal issues, including contracts, property, torts, and family law.
         Civil lawyers specialize in handling these types of cases and providing legal representation to their 
         clients. In Chennai,`,
         walk:`/civil`
    }];
    
  return (
    <section section className="mt-[190px] pb-10 m-auto px-5 flex justify-center flex-wrap" style={{gap:"20px"}} id="service">
        {
        data.map((d,i)=>(
          <div key={i}>
          <div className="card p-3">
               <h2 className="text-white font-bold pb-2">{d.title}</h2>
               <p className="text-white">{d.description}</p>
           </div>
           <button className="read-btn border flex items-center justify-center" onClick={()=>navigate(d.walk)}>
           <FaAngleDown size={30} className="ml-[10px]"/>
           </button>
           </div>
        ))
      }
      
    </section>
  )
}

export default CardSlider;