import React from 'react';
import Contacts from './Contacts';
import Contactss from './Contactss';

const Contact = () => {
  return (
    <>
        <Contacts />
        <Contactss />
    </>
  )
}

export default Contact