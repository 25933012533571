import React from 'react';
import './BlogRoutes.css';
import { useNavigate } from 'react-router-dom';

const MoneyFraud = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-28 px-10 mb-6">
      <div>
        <h2>Measures Taken After Money Fraud Occurs</h2>
        <p>Money fraud is a type of crime that can have severe consequences for both the victim and the perpetrator. 
          It involves the illegal acquisition or use of money through deception or coercion. After money fraud occurs,
           there are several measures that need to be taken to ensure justice is served and that victims are able to recover
            from the financial damage caused.</p>
        </div>
        <div>
          <h2>Investigation of Money Fraud</h2>
          <p>
          After money fraud occurs, law enforcement agencies must conduct a thorough investigation to gather 
          evidence and identify the cause and perpetrators of the crime. This process involves collecting and 
          analyzing various types of evidence, including financial records, witness statements, and surveillance footage. 
          During the investigation, investigators will examine bank statements, credit card statements, and other financial 
          records to identify any unusual transactions or patterns of behavior. They may also interview witnesses who may have information
           or witnessed the crime taking place. Additionally, investigators may utilize tools such as forensic accounting to analyze 
           financial data and identify potential fraudulent activity.
          </p>
          <p>
          In the recent Chennai money fraud case, the investigators followed similar measures to ensure justice.
           They meticulously collected and analyzed financial records, interviewed witnesses, and utilized forensic
            accounting techniques to uncover the extent of the fraud and identify the individuals involved. This
             comprehensive investigation played a vital role in gathering evidence and building a strong case against
              the perpetrators.
          </p>
        </div>
        <div>
          <h2>Legal Proceedings to Take Place</h2>
          <p>
          Once sufficient evidence is collected, legal proceedings need to be initiated to bring the 
          perpetrators to justice and recover financial losses for victims.
          Filing a Lawsuit: The first step in legal proceedings is to file a lawsuit against the perpetrator(s) 
          of the money fraud. This can be done through the district attorney’s office or a private attorney. 
          The lawsuit serves as a formal accusation that the defendant committed the crime and seeks compensationfor the victims.
          Serving the Defendant with the Lawsuit: Once the lawsuit is filed, it must be served on the defendant. This 
          ensures that the defendant is aware of the legal proceeding against them and provides them an opportunity to respond.
           The service of lawsuit can be done through certified mail, personal service, or other methods authorized by the court.
          Preparing for Trial: If a settlement cannot be reached during the legal proceedings, the case may proceed to trial.
           At this stage, both sides will engage in discovery to gather more information, exchange evidence, and prepare their 
           case for presentation before the court. The prosecution will present evidence to prove the defendant's guilt, while
            the defense will put forth their arguments and evidence to dispute the allegations.
          </p>
        </div>
        <div>
          <h2>Financial Restoration for Victims</h2>
          <p>
          In addition to seeking justice through the legal process, it is important to provide financial restoration
           for victims of money fraud. Financial assistance can help victims cover out-of-pocket costs such as medical 
           expenses, loss of earnings, and legal fees.
          </p>
          <p>
          Restitution for Victims: In some jurisdictions, victims of money fraud may be entitled to seek restitution 
          from the perpetrators. Restitution aims to return the victim to the financial state they were in prior to the fraud, 
          by recovering stolen funds or property, or providing financial compensation for financial losses.
          </p>
          <p>
          It is important for victims of money fraud to seek legal help to ensure their rights are protected and they
           receive the necessary assistance in recovering from the financial losses incurred.
           Consulting with an experienced attorney can provide support and guidance throughout the investigation and legal proceedings.
          </p>
        </div>
        <div>
          <h2>conclusion</h2>
          <p>
          In conclusion, money fraud is a complex crime that requires a concerted effort to investigate, prosecute,
           and provide financial restitution for victims. By taking these measures, law enforcement agencies, legal professionals,
            and organizations can help ensure that justice is served, and victims are properly compensated for their losses. Raising awareness 
          about money fraud and the consequences it brings can help in preventing such crimes from occurring in the future.
          </p>
        </div>
        <div className="prev-btn-blog"><button onClick={()=>navigate('/')}>Go to Previous</button></div>
    </div>
  )
}

export default MoneyFraud