import React from 'react';
import BlogSlider from './BlogSlider';

const Blog = () => {

  return (
    <>
      <BlogSlider />
    </>
  )
}

export default Blog