import React from 'react';
import '../blog-routes/BlogRoutes.css';
import {useNavigate} from 'react-router-dom';

const Civil = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-28 mb-6 px-10">
        <div>
            <h2>Civil Law</h2>
            <p>Civil law is a legal system that governs disputes between individuals or organizations.
                 It encompasses a wide range of legal issues, including contracts, property, torts, and family law.
                  Civil lawyers specialize in handling these types of cases and providing legal representation to their 
                  clients. In Chennai, civil lawyers play a crucial role in resolving civil disputes and ensuring justice is
                   served.</p>
        </div>
        <div>
            <h2>Civil lawyers in Tambaram</h2>
            <p>When considering hiring civil advocate in Tambaram, there are certain factors and questions that
                 need to be taken into account. First and foremost, it is important to find a lawyer with relevant 
                 experience and credentials. Civil lawyers typically specialize in specific areas of law, such as
                  personal injury, property disputes, or commercial litigation.
                 It is essential to choose a lawyer who has the expertise and knowledge to handle the particular legal issue in question.</p>
            <p>Secondly, it is essential to consider the cost associated with hiring civil advocate. Civil lawyers typically
                 charge by the hour, and some also offer flat rates for certain services. It is important to get quotes from 
                 multiple lawyers and compare their rates to find the most cost-effective option.
                 Additionally, some lawyers may offer payment plans or discounts for clients who require ongoing representation.</p>
            <p>Lastly, it is important to conduct thorough interviews with potential civil lawyers to understand their professional
                 approach and philosophy. Ask them about their success rate in civil cases, how they handle disputes, 
                 and their opinion on engaging early in the process to avoid costly litigation. It is also beneficial 
                 to ask for references from past clients or colleagues who can attest to the lawyer's abilities and 
                 trustworthiness.</p>
        </div>
        <div>
            <h2>As part of our legal services, we provide those relating to</h2>
            <p>1.property disputes, </p>
            <p>2.landlord-tenant disputes, </p>
            <p>3.summary suits, </p>
            <p>4.MCOPs, etc</p>
        </div>
        <div className="prev-btn-blog"><button onClick={()=>navigate('/')}>Go to Previous</button></div>
    </div>
  )
}

export default Civil