import React from 'react';
import './About.css';
import CourtPic from '../../images/courtpic.jpg';
import SamNine from '../../images/samNine.jpg';

const About = () => {
  return (
    <div className="px-10 mt-[150px] h-[70vh]" id="about">
        <div className="about-box1 md:hidden pt-5">
            <div className="h-[200px] about-pic-head">
              <img src={SamNine} alt='pic' className="h-[200px]"/>
            </div>
            <div className="pt-4">
                <h1 className="text-start pb-3 font-bold" style={{fontSize:"18px",letterSpacing:"1.2px"}}>Mujib Law Associate :</h1>
                <p className="text-justify contents-para pb-5" style={{letterSpacing:"1.2px"}}>
                    Mujib Law Associate is a leading law Firm specializing in Muslim
                    law, Criminal law, Civil law and Family law. With a team of
                    experienced and highly skilled lawyers, we are committed to 
                    providing comprenhensive legal solutions tailored to meet the needs 
                    of our clients.
                </p>
            </div>
            <div className="flex justify-between pb-5 gap-8 banner-about2">
              <div className="w-[200px] text-about2">
                <p style={{fontSize:"13px",fontWeight:"700"}}>Why Choose Mujib<br>
                </br>Law Associate?</p>
                <div className="w-[150px] h-[100px] bg-red-200 overflow-auto text-justify">
                    <p className="px-1 py-1">
                    Mujib Law Associate is a leading law Firm specializing in Muslim
                    law, Criminal law, Civil law and Family law. With a team of
                    experienced and highly skilled lawyers, we are committed to 
                    providing comprenhensive legal solutions tailored to meet the needs 
                    of our clients.
                    </p>
                </div>
              </div>
              <div className="court-pic">
                <img src={CourtPic} alt="pic"/>
              </div>
            </div>
        </div>

        <div className="about-box2 hidden md:block">
          <div className="flex flex-row gap-6 mb-7">
            <div className="h-[250px] w-1/2 text-image-content flex justify-center">
            <img src={SamNine} alt='pic' className="h-[250px] w-[700px]"/>
            </div>
            <div className="pt-4 w-1/2 mt-[-20px] px-5">
                  <h1 className="text-start pb-6 font-bold text-head-content" style={{letterSpacing:"1.2px"}}>Mujib Law Associate :</h1>
                  <p className="text-justify contents-para pb-5" style={{letterSpacing:"1.2px"}}>
                      Mujib Law Associate is a leading law Firm specializing in Muslim
                      law,Criminal law, Civil law and Family law. With a team of
                      experienced and highly skilled lawyers, we are committed to 
                      providing comprenhensive legal solutions tailored to meet the needs 
                      of our clients.
                  </p>
              </div>
          </div>
          <div className="mb-5 flex flex-row gap-7 banner-about2-gap">
            <div className="container-head1 overflow-auto">
              <p className="para-head1">Why Choose Mujib Law Associate?</p>
              <div className="sub-para-head1">
                <p><span>1. Specialized Expertise</span> : Mujib Law Associates dedicates itself
                  to Muslim law, Criminal law and family law. Our lawyers are well-versed
                  in there areas and offere expert advise and representation.
                </p>
                <p><span>2. Experienced Lawyers</span> : Experienced lawyers with a track record of success in their
                 respective fields guide us through complex legal matters with confidence and efficiency.
                </p>
                <p><span>3. Client-Centric Approach: </span> : Mujib Law Associates prioritizes our clients' needs and objectives. We value building strong client relationships through trust, integrity, and effective communication. 
                By understanding our clients' individual circumstances, we provide personalised legal advice.
                </p>
                <p><span>4. Effective Representation</span> : The Advocate at our firm are skilled advocates, doing everything in
                 their power to ensure that our clients' rights and interests are protected.
                </p>
                <p><span>5. Confidentiality and Privacy</span> : Mujib Law Associates understands the importance of confidentiality and maintains
                 strict privacy standards to protect our clients' information.
                </p>
              </div>
            </div>
            <div className="show-banner-about2">
              <img src={CourtPic} alt='pic'/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default About;