import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cup from '../../images/cup.png';
import Happyclient from '../../images/Happyclient.png';
import {FaAngleDown} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';

const CardSliders = () => {
  const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      const data = [{
        title:"Criminal Law :",
        description:`Criminal law is a complex system that governs criminal offenses and penalties.
        It encompasses a wide range of offenses, including violent crimes, property crimes,
         white-collar crimes.`,
         walk:`/family`
    },{
        title:"Family Law :",
        description:`Family Law is a branch of law that deals with legal matters related to family and personal relationships.
        It encompasses a wide range of issues, including marriage, divorce, child custody,`,
        walk:`/criminal`
    },{
        title:"Muslim law :",
        description:`Muslim Law, also known as Shariah Law, is a legal system that 
        is based on the teachings and principles of Islam. It governs various aspects of life, such as personal status, family relations, and.`,
        walk:`/family`
    },{
        title:"Civil Law :",
        description:`Civil law is a legal system that governs disputes between individuals or organizations.
        It encompasses a wide range of legal issues, including contracts, property, torts, and family law.
         Civil lawyers,`,
         walk:`/civil`
    }];

  return (
    <section section className="mt-[60px] m-auto px-5 flex justify-center items-center flex-wrap" id="service">
    <div className="res-card-container">
        <Slider {...settings}>
        {
        data.map((d,i)=>(
          <div key={i}>
            <div className="p-3 text-justify">
               <h2 className="text-white font-bold" style={{fontSize:"18px"}}>{d.title}</h2>
               <p className="text-white">{d.description}</p>
           </div>
           <button className="text-white bg-gray-300 py-1 px-2 ml-[95px] mt-[-10px]" style={{borderRadius:"50px"}} onClick={()=>navigate(d.walk)}>
           <FaAngleDown size={30}/>
           </button>
           </div>
        ))
      }
        </Slider>
    </div>
    <div className="w-3/4 mt-[100px] flex items-center justify-center gap-7 flex-wrap ml-[40px] service-logo">
      <div className="mini-logo">
        <img src={Happyclient} alt="pic"/>
        <p className="flex flex-col">
          50+<span>Clients</span>
        </p>
      </div>
      <div className="mini-logo">
        <img src={Cup} alt="pic"/>
        <p className="flex flex-col">
          70+<span>Meetings</span>
        </p>
      </div>
      <div className="mini-logo">
        <img src={Cup} alt="pic"/>
        <p className="flex flex-col">
          40+<span>Judgements</span>
        </p>
      </div>
      <div className="mini-logo">
        <img src={Cup} alt="pic"/>
        <p className="flex flex-col">
          10 Years<span>Experience</span>
        </p>
      </div>
    </div>
    </section>
  )
}

export default CardSliders;