import React from 'react';
import { useNavigate } from 'react-router-dom';

const Tribunal = () => {
    const navigate = useNavigate();
  return (
    <div className="mt-28 mb-6 px-10">
        <div>
            <h2>Tamil Nadu Waqf Board Tribunal:</h2>
            <p>
            Tamil Nadu Waqf Board Tribunal is a specialized tribunal created under the Tamil Nadu Waqf Act, 2013.
             It deals with administering and regulating waqfs (endowments) in Tamil Nadu. The primary objective of
              the Tamil Nadu Waqf Board Tribunal is to ensure the effective management
             and protection of waqfs and the equitable distribution of their benefits.
            </p>
        </div>
        <div>
            <h2>Jurisdiction and powers of the Tamil Nadu Waqf Board Tribunal:</h2>
            <p>The jurisdiction and powers of the Tamil Nadu Waqf Board Tribunal are broadly categorized into two types: original jurisdiction and appellate jurisdiction.</p>
            <p>Original jurisdiction refers to the power of the Tamil Nadu Waqf Board Tribunal to adjudicate matters in the first instance. 
                This includes disputes related to the administration, management, or alienation of waqfs. The Tamil
                 Nadu Waqf Board Tribunal has the power to issue various orders and directions to ensure the proper management of waqfs.</p>
            <p>On the other hand, Appellate jurisdiction refers to the power to review decisions or orders of lower courts or authorities in matters related to waqfs.
                 The Tamil Nadu Waqf Board Tribunal can hear appeals against such decisions and pass appropriate orders.</p>
            <p>The types of cases the Tamil Nadu Waqf Board Tribunal hears include disputes related to ownership, possession, and administration of waqf properties. It also deals with matters relating to the
                 devolution of waqf properties, succession, and inheritance of waqf properties.</p>
            <p>Legal procedures before the Tamil Nadu Waqf Board Tribunal vary depending on the nature of the case.
                 However, parties are generally required to file a petition or application with the Tribunal, along 
                 with supporting documents and evidence. The Tribunal will then fix a date for hearing the matter and 
                 issue notice to the parties. During the hearing, both parties will have the opportunity to present
                 their arguments and evidence. The Tribunal will then consider all the evidence presented and decide.</p>
        </div>
        <div>
            <h2>Role of the Tamil Nadu Waqf Board Tribunal:</h2>
            <p>
            The Tamil Nadu Waqf Board Tribunal ensures waqfs' fair and efficient administration. It acts as a neutral
             and impartial authority that ensures that the rights of waqf beneficiaries
             are protected and that waqf properties are used for their intended purpose.
            </p>
            <p>The decision-making process of the Tamil Nadu Waqf Board Tribunal is based on the principles of fairness
                 and justice. The Tribunal considers the arguments and evidence presented by both parties and decides based 
                 on applicable laws and principles. The decisions of the Tamil Nadu Waqf 
                Board Tribunal are binding on the parties and are enforceable through the appropriate legal channels.</p>
            <p>The Tamil Nadu Waqf Board Tribunal can issue various orders related to waqfs. These orders may include 
                directions for the proper administration of waqfs, appointment of administrators, recovery of waqf properties, 
                or settlement of disputes. The orders issued by the Tamil
                 Nadu Waqf Board Tribunal aim to ensure the effective management and protection of waqf properties.</p>
        </div>
        <div>
            <h2>Case studies of the Tamil Nadu Waqf Board Tribunal</h2>
            <p>Over the years, the Tamil Nadu Waqf Board Tribunal has adjudicated various important waqf cases.
                 These cases have set important precedents and have 
                highlighted the Tribunal's role in safeguarding the interests of waqf beneficiaries.<br></br>
                One notable case the Tamil Nadu Waqf Board Tribunal decided involved a dispute regarding the mismanagement
                 of a waqf property. The Tribunal took suo moto action against the managing committee of the waqf
                 and ordered the appointment of an independent administrator to bring the property back into proper use.<br></br>
                 In another significant case, the Tamil Nadu Waqf Board Tribunal ordered the recovery of waqf properties illegally
                  alienated. This case highlighted the
                  importance of safeguarding waqf properties and ensuring their benefits reach the intended beneficiaries.<br></br>
                  The outcome of important cases decided by the Tamil Nadu Waqf Board Tribunal has ensured that waqf properties are
                   protected and used for the benefit and welfare of the community. The Tribunal's decisions have ensured that waqf properties
                   are used according to their Islamic principles and for the community's socio-economic development.
                </p>
                <p>In conclusion, the Tamil Nadu Waqf Board Tribunal plays a crucial role in administering
                     and regulating waqfs in Tamil Nadu. It ensures the fair and efficient management of waqf
                      properties and protects the rights of waqf beneficiaries. The Tribunal's jurisdiction, powers,
                       and decision-making processes ensure that waqf properties are used for their intended purpose 
                       and the benefit and welfare of the community. Its case studies serve as important precedents and
                        highlight the Tribunal's commitment to safeguarding the interests of waqf beneficiaries.</p>
        </div>
        <div className="prev-btn-blog"><button onClick={()=>navigate('/')}>Go to Previous</button></div>
    </div>
  )
}

export default Tribunal