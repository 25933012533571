import React, {useRef} from 'react';
import './Career.css';
import emailjs from '@emailjs/browser';
import CourtMarshal from '../../images/CourtMarshal.jpg';

const Career = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_g4gbobs', 'template_rs45pdn', form.current, {
            publicKey: '3vIGn7XECHu2iqk_W',
          })
          .then(
            (result) => {
              console.log(result.text);
              console.log("message sent");
            },
            (error) => {
              console.log(error.text);
            },
          );
          alert('form submited');
      };

  return (
    <div className="mt-18 mb-[70px] px-5 form-container" id="career">
        <div className="form-container-img flex justify-center">
            <img src={CourtMarshal} alt='pic'/>
        </div>
        <div className="form-box">
        <form ref={form} onSubmit={sendEmail} className="mt-[60px]">
            <h2 className="pl-5 form-heading">REGISTRATION FORM :</h2>
                <div className="flex flex-col px-5">
                    <label htmlFor="firstname">First Name</label>
                    <input type="text" name='user_name' id="firstname" required />
                </div>
                <div className="flex flex-col px-5">
                    <label htmlFor="contact">Contact Number</label>
                    <input type="text" name='user_contact' id="contact" required/>
                </div>
                <div className="flex flex-col px-5">
                    <label htmlFor='district'>District</label>
                    <input type="text" name='user_district' id="district" required/>
                </div>
                <div className="flex flex-col px-5">
                    <label htmlFor="email">Email Address</label>
                    <input type="email" name='user_email' id="email" required/>
                </div>
                <div className="flex flex-col px-5">
                    <label htmlFor='state'>State</label>
                    <input type="text" name='user_state' id="state" required/>
                </div>
                    <div className="flex flex-col px-5 pb-5">
                    <label htmlFor='pincode'>Pincode</label>
                    <input type="text" name='user_pincode' id="pincode" required/>
                    <button type='submit' className='send-btn'>Submit</button>
                </div>
        </form>
        </div>
    </div>
  )
}

export default Career