import React from 'react'
import { useNavigate } from 'react-router-dom'

const Muslim = () => {
    const navigate = useNavigate();

  return (
    <div className="mt-28 mb-6 px-10">
        <div>
            <h2>What is Muslim Law?</h2>
            <p>Muslim Law, also known as Shariah Law, is a legal system that is based on the teachings and principles of Islam.
                 It governs various aspects of life, such as personal status, family relations, and financial transactions. 
                 Muslim advocate in Tambaram are experts in this area of law and can provide valuable assistance and guidance 
                 to their clients.Benefits of Hiring Muslim Lawyers in Tambaram</p>
        </div>
        <div>
            <h2>Understanding Muslim Law:</h2>
            <p>Muslim Advocate in Tamabram have a deep understanding of the Muslim Law and its principles.
                 This allows them to provide comprehensive legal advice
                 and representation to their clients, ensuring that their interests are protected.</p>
        </div>
        <div>
            <h2>Access to Resources</h2>
            <p>Muslim lawyers in Tambaram have access to a wide range of resources, including libraries, journals, and experts
                 in the field. This allows them to stay up-to-date with the latest developments in Muslim law and provides
                 them with the necessary knowledge to navigate through complex legal issues.</p>
        </div>
        <div>
            <h2>Representation in Court:</h2>
            <p>Muslim advocate in Tambaram are experienced in representing clients in court proceedings related to Muslim Law. 
                They understand the legal procedures and can navigate the complex legal system effectively. 
                Their presence in the courtroom can help to ensure a fair and just outcome for all parties involved.</p>
        </div>
        <div className="prev-btn-blog"><button onClick={()=>navigate('/')}>Go to Previous</button></div>
    </div>
  )
}

export default Muslim