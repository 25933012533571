import React from 'react'
import HammerLock from '../../images/hammerLock.jpg';
import TripleTalaq from '../../images/tripleTalaq.jpg';
import Mosque from '../../images/mosque.jpg';
import './Blog.css';
import {useNavigate} from 'react-router-dom';

const BlogSlider = () => {

  const navigate = useNavigate();

    const blog = [
        {
            heading:`Recetly Updated`,
            picture:TripleTalaq,
            tittle:`A critical study of the Supreme Court's decision on the Triple Talaq.`,
            dob:`March 1,2024`,
            description:`The Triple Talaq, also known as instantaneous divorce, is a controversial practice
             prevalent`,
            walk:`/triple`
        },{
          heading:`Most Viewed`,
          picture:HammerLock,
            tittle:`Measures Taken After Money Fraud Occurs.`,
            dob:`March 1,2024`,
            description:`Money fraud is a type of crime that can have severe consequences for both the victim and the perpetrator.`,
            walk:`/money`
        },{
            heading:`Most Liked`,
            picture:Mosque,
            tittle:`Tamil Nadu and Waqf Board Tribunal.`,
            dob:`March 1,2024`,
            description:`Tamil Nadu Waqf Board Tribunal is a specialized tribunal created under the Tamil Nadu Waqf Act, 2013.
            It deals with`,
            walk:`/tribunal`
        }
    ];

  return (
    <section section className="mt-20 pb-10 m-auto px-5 flex justify-center flex-wrap blog-card" style={{gap:"50px"}} id="blog">
        {
        blog.map((b,i)=>(
          <div className="flex flex-col gap-3 flex-wrap items-center justify-center blog-card-img" key={i}>
          <div className="bg-red-700 h-[40px] flex items-center justify-center w-[280px] blog-heading1" style={{letterSpacing:"1.2px",borderRadius:"3px"}}>
            <h2 className="text-white text-lg font-semi-bold">{b.heading}</h2>
          </div>
        <div>
          <div className="card-blog flex items-center flex-col p-3">
            <div className="blog-heading2">
                <p className="text-center pb-2 font-semi-bold" style={{borderBottom:"3px solid #fff"}}>{b.tittle}</p>
                <p className="text-center pb-2">{b.dob}</p>
            </div>
            <div>
                <img src={b.picture} alt="pic" className="object-cover pb-2"/>
            </div>
            <div className="blog-heading3">
               <p className="text-center pb-2" style={{fontSize:"16px",fontWeight:"500"}}>{b.description}</p>
               <button className="border bg-white text-red-300 justify-center px-2 py-1 ml-[80px] mt-[-15px]" style={{borderRadius:"10px"}} onClick={()=>navigate(b.walk)}>
                Read More
                </button>
            </div>
           </div>
        </div>
        </div>
        ))
      }
      
    </section>
  )
}

export default BlogSlider