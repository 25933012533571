import React from 'react';
import './Contact.css';
import {FaInstagram,FaFacebook,FaWhatsapp} from 'react-icons/fa';
import SamNine from '../../images/samNine.jpg'; 

const Contacts = () => {
  return (
    <div className="mt-2 px-5 mb-10 md:hidden">
        <div className="flex flex-col">
            <div className="h-[200px] flex justify-center">
                <img src={SamNine} alt='pic' className="h-[200px]"/>
            </div>
            <div className="contact-content">
                <h2 className="text-center">mujib law associate</h2>
                <p className="text-center">No : 10, Rs Tower,<br></br>
                bakthavatchalam st,<br></br>
                West tambaram,<br></br>chennai - 600045.
                </p>
                <ul className="flex items-center justify-center mt-2 gap-2">
                    <li className="text-red-600"><FaWhatsapp /></li>
                    <li className="text-blue-600"><FaFacebook /></li>
                    <li className="text-pink-400"><FaInstagram /></li>
                </ul>
            </div>
        </div>
        <div className="flex justify-center flex-col items-center">
            <div className="mt-5 map-content">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31107.58254351405!2d80.1073382510468!3d12.94317144163371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525fca341ad753%3A0xfa2cac60c7474897!2sR.S.TOWER!5e0!3m2!1sen!2sin!4v1712667042861!5m2!1sen!2sin" style={{width:"600",height:"450",style:"border:0;",allowfullscreen:"",loading:"lazy",referrerpolicy:"no-referrer-when-downgrade"}}></iframe>
            </div>
            <ul className="flex flex-col items-center justify-center gap-[5px]">
                <li className="mt-[10px]">+91 9894208186</li>
                <li>mujiburahman786@gmail.com</li>
                <button className="mail-btn">SEND MAIL</button>
            </ul>
        </div>
    </div>
  )
}

export default Contacts;