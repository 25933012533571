import React from 'react';
import {useNavigate} from 'react-router-dom'

const Family = () => {
    const navigate = useNavigate();

  return (
    <div className="mt-28 mb-6 px-10">
        <div>
            <h2>Criminal Law:</h2>
            <p>Criminal law is a complex system that governs criminal offenses and penalties.
                 It encompasses a wide range of offenses, including violent crimes, property crimes,
                  white-collar crimes, and other crimes that threaten the safety and welfare of society.
                   The purpose of criminal law is to protect
                 the community from wrongdoing and to hold individuals accountable for their actions.</p>
        </div>
        <div>
            <h2>Role of Advocate in Criminal Law</h2>
            <p>The role of lawyers in criminal law is crucial, as they represent the rights and interests 
                of both defendants and victims. Legal representation ensures
                 that defendants are treated fairly and receive the necessary assistance throughout the legal process.</p>
            <p>Lawyers act as advocates for defendants, providing them with legal representation and protecting their rights.
                 They assist in investigating the case, gathering evidence, and interviewing witnesses. 
                They challenge the prosecution's evidence and argue for a reduced sentence or alternative sentencing options.</p>
            <p>Additionally, Advocate in Tambaram also play a crucial role in assisting prosecutors. They work closely 
                with law enforcement agencies, providing legal advice and helping with the preparation and presentation of cases.
                 Lawyers may also contribute to the community's 
                safety by collaborating with prosecutors on innovative strategies for preventing and addressing crime.</p>
        </div>
        <div>
            <h2>How to choose the right lawyers for criminal cases in Chennai</h2>
            <p>In Chennai, several renowned and respected Advocate specialize in criminal law.
                 These lawyers have extensive experience
                 and expertise in handling various types of criminal cases.</p>
            <p>To qualify as a top lawyer in Tambaram, an individual must have completed a rigorous legal education 
                and obtained a license to practice law. 
                Additionally, they must have a proven track record of success in representing clients in various criminal cases.</p>
            <p>The experience of top lawyers in Tambaram is crucial in navigating the complexities of 
                the criminal justice system. They have handled a wide range of criminal cases, including violent crimes,
                 white-collar crimes, and drug-related offenses. Their experience allows them to effectively advocate for 
                 their clients and provide them with the best possible legal representation.</p>
            <p>Choosing the best lawyer for your case is crucial, as the outcome of a criminal case can significantly impact 
                your life. It is essential to consult with lawyers who have significant experience in criminal law
                 and who are known for their professionalism and dedication to justice.</p>
        </div>
        <div>
            <h2>Legal services we provide at our firm include those related to:</h2>
            <p> Bail, </p>
            <p>Anticipatory bail, </p>
            <p>Quashing of the FIR, </p>
            <p>Charge Sheets, </p>
            <p>Warrant Recall, etc.</p>
        </div>
        <div className="prev-btn-blog"><button onClick={()=>navigate('/')}>Go to Previous</button></div>
    </div>
  )
}

export default Family