import React, { useState } from 'react';
import './Header.css';
import mujibLogo from '../../images/mujib-logo.jpg';
import {FaBars,FaTimes} from 'react-icons/fa';

const Header = () => {

    const [menubar,setMenuBar] = useState(false);

  return (
    <header className="flex justify-center fixed top-0 left-0" style={{width:"100%",boxSizing:"border-box",alignItems:"center",backgroundColor:"whiteSmoke",zIndex:"240"}}>
        <div className="flex justify-between px-10 mt-3 mb-3 nav-box" style={{width:"90%",alignItems:"center"}}>
            <div className="flex">
                <img src={mujibLogo} alt='mujib-logo' className="logo logo-hide"/>
                <p className="px-2 font-semibold text-head">MUJIB<span className="block">LAW ASSOCIATE</span></p>
                <button onClick={()=>setMenuBar(!menubar)} className="btn-menu hidden menu-show">
                        {
                            menubar ? <FaTimes /> : <FaBars />
                        }
                </button>
            </div>
            <nav className='mobile-hide'>
                <ul className="flex">
                    <li className={menubar && "item"}><a href='#home'>Home</a></li>
                    <li className={menubar && "item"}><a href='#about'>about</a></li>
                    <li className={menubar && "item"}>
                    <a href='#career'>career</a>
                    </li>
                    <button onClick={()=>setMenuBar(!menubar)} className="btn-menu">
                        {
                            menubar ? <FaTimes /> : <FaBars />
                        }
                    </button>
                    <li className={menubar && "item"}><a href="#service">services</a></li>
                    <li className={menubar && "item"}><a href='#blog'>blog</a></li>
                    <li className={menubar && "item"}><a href="#contact">contact</a></li>
                </ul>
            </nav>
            {
                menubar && <nav className='mobile-show hidden fixed'>
                <ul className="flex flex-col">
                    <li className={menubar && "item"} onClick={()=>setMenuBar(!menubar)}><a href='#home'>Home</a></li>
                    <li className={menubar && "item"} onClick={()=>setMenuBar(!menubar)}><a href='#about'>about</a></li>
                    <li className={menubar && "item"} onClick={()=>setMenuBar(!menubar)}>
                        <a href='#career'>career</a>
                    </li>
                    <li className={menubar && "item"} onClick={()=>setMenuBar(!menubar)}><a href="#service">services</a></li>
                    <li className={menubar && "item"} onClick={()=>setMenuBar(!menubar)}><a href='#blog'>blog</a></li>
                    <li className={menubar && "item"} onClick={()=>setMenuBar(!menubar)}><a href="#contact">contact</a></li>
                </ul>
            </nav>
            }
            <div className="hidden logo-box">
                <img src={mujibLogo} alt='mujib-logo' className="logo"/>
            </div>
        </div>
    </header>
  )
}

export default Header